import React from "react"
import BackgroundShape from "../components/backgroundShape"
import CardFeature from "../components/cardFeature"
import Container from "../components/new/container/container"
import Layout from "../components/layout"
import Head from "../components/head"
import Header from "../components/header"
import { graphql, Link } from "gatsby"
import Grid from "../components/new/grid/grid"
import MDXRenderer from "gatsby-plugin-mdx/mdx-renderer"
import { MDXProvider } from "@mdx-js/react"


export const query = graphql`
  query($slug: String!) {
    contentfulFeatureCategory(slug: { eq: $slug }) {
    	title
      	slug
		description
		order
		icon
		summary {
			childMdx {
				body
				}
			}
      	feature {
        	title
			image
			metaTitle
			metaDescription {
				metaDescription
			}
			description {
				childMdx {
					body
				}
			}
        	slug
			summary {
				summary
			}
      	}
    }
    allContentfulFeatureCategory{
        edges{
        	node {
            	title
            	slug
				order
          	}
        }
      }
  }
`
const FeatureLayout = (props) => {

	return (
		<Layout navbar="transparent">
			<Head title="Partners" description="Our cloud based software helps you connect with multiple vendors and manage all your ecommerce operations from a single platform" />
			<BackgroundShape style={{ shape: "clip-fixed", color: "bg-enterprise-sm", particles: false }} />
			<Container style={{ classes: "container position-relative" }}>
				<Header content={{ title: props.data.contentfulFeatureCategory.title, text: props.data.contentfulFeatureCategory.description }} style={{ color: "text-center pb-5" }} />
				<div className="desktop-only">	
					<Grid style='grid-8'>
						{props.data.allContentfulFeatureCategory.edges.sort((a, b) => a.node.order - b.node.order).map((edge) => {
							return (
								<Link to={'/' + edge.node.slug} className='feature-category-filter' activeClassName="feature-category-filter__active" >{edge.node.title}</Link>
							)
						})}
					</Grid>
				</div>
			</Container>
			<Container style='my-5 pb-5'>
				<div className="row">
					<div className="col-12 col-md-5">
						<div style={{background: '#f7f7f8', textAlign: 'center', padding: '1rem', fontSize: '1rem'}}>
							<img src={`/images/category/${props.data.contentfulFeatureCategory.slug}.svg`} style={{width:'100%'}} className="mt-2 mb-4"/>
							<div className="feature-summary" data-sal='fade' data-sal-delay='300' data-sal-easing="ease" >
							<MDXProvider>
								<MDXRenderer>
									{props.data.contentfulFeatureCategory.summary.childMdx.body}
								</MDXRenderer>
							</MDXProvider>
							</div>
						</div>
					</div>
					<div className="col-12 col-md-7">
							{props.data.contentfulFeatureCategory.feature.sort((a, b) => b.title.localeCompare(a.title)).map((feature) => {
								return (
									<CardFeature content={{
										title: feature.title,
										slug: feature.slug,
										summary: feature.description.childMdx.body,
										image: feature.image,
									}} />
								)
							})}
					</div>
				</div>
			</Container>
		</Layout>
	)
}


export default FeatureLayout
